@import 'toro-styles/scss/main.scss';

html,
body {
    height: 100%;
}

body {
    background-color: #ffffff;
}

.icon.round {
    background-color: rgba(236, 237, 238, 1);
    border-radius: 18px;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.font-silver {
    color: #5b6067 !important;
}

.form-check-label {
    margin-top: 0.7rem !important;
}

.form-check-inline {
    padding: 0;
}

.link-store {
    font-size: 0.875rem;
    text-decoration: underline;
    margin-left: 0.2rem;
    color: #6131b4;
}
.hero {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 90%;
}

.toro-logo {
    width: 96px;
    height: auto;
}

.login-card {
    padding: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff;
    overflow: hidden;
}

.layout-title {
    margin-bottom: 30px;
}

.layout-subtitle {
    margin-bottom: 24px;
}

.layout-content form {
    display: flex;
    flex-direction: column;
}

.input-field .form-input {
    background: #f6f8fb;
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid #eae9f0;
    outline: 0;
    height: 40px;
    width: 100%;
    padding: 8px 16px;
    margin-bottom: 8px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 27px;
    transition: all 0.3s;
}

.input-field .input-error-message {
    color: #f44336;
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    text-align: right;
    float: right;
}

.input-field.input-error .form-input {
    color: #f44336;
    border-color: #f44336;
    background-color: #fee9eb !important;
}

.form-error-message {
    color: #f44336;
    font-size: 0.75rem;
    font-weight: 500;
}

.client-name {
    text-decoration: underline;
}

.button-forgot-password {
    margin-top: 24px;
}

.button-resend-mfa {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
        padding: 0;
    }
}

#root,
#root > .container {
    height: 100%;
}

#root > div {
    div.container {
        padding-bottom: 25px;
    }
}

.full-page-layout {
    height: calc(100% - 68px);
    padding-bottom: 0px !important;

    div {
        height: 100%;
    }
}

.iframe-term {
    width: calc(100% + 64px);
    height: 100%;
    border: none;
    margin-left: -32px;
}

.container-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-disable {
    border: none;
    opacity: 0.5 !important;
    color: #6131b457 !important;
}

.btn-outline-primary {
    border: 2px solid #dee2e6 !important;
    background-color: transparent !important;
    color: #6131b4 !important;
}

.btn-icon {
    width: 49%;
    height: 4.875rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 5px;
    opacity: 1;
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    img {
        width: 1.5rem;
    }
}

.btn--full {
    width: 100%;
}
.MuiButtonBase-root {
    width: 2.375rem !important;
    padding: 0 !important;
    margin-top: 0.625rem !important;
}
.MuiToolbar-gutters {
    padding-left: 0 !important;
}

.MuiSvgIcon-root {
    width: 1rem !important;
    height: 2.3rem !important;
    margin-top: 0.188rem;
}

.img-qrcode {
    padding: 0.7rem 0 1.5rem 0;
}

.btn-app {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 0 1.5rem 0;
    width: 100%;
}

.font-label {
    font-size: 1rem;
}

.font-h4 {
    font-size: 1.313rem;
}
#header {
    height: auto;
}

.hide {
    display: none !important;
}

// CARROSEL
.onboarding-carousel {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
    width: 100%;
    overflow: hidden;

    .carousel-item-auth {
        display: inline-flex;
        justify-content: center;
        margin-top: 16px;
        width: 100%;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0 1rem 0 1rem;

        .carousel-header {
            display: flex;
            min-height: 2rem;
            width: 68%;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: baseline;
            flex-direction: row;
            max-width: 15.813rem;
            .number-content {
                height: 31px;
                width: 31px;
                border-radius: 15.5px;
                background-color: #f8f8f8;
                font-weight: 700;
                font-size: 1rem;
                color: #990000;
                padding-top: 4px;
                text-align: center;
            }

            .text-content {
                text-align: center;
                font-size: 1.125rem;
                padding: 0px 5px;
                width: 100%;
            }
        }

        .carousel-image-page {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .carousel-image {
                max-height: 40vh;
                object-fit: contain;
            }

            .page-button {
                width: 24px;
                height: 24px;
                border-radius: 12px;
                background-color: #eae9f0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;

                &.next {
                    rotate: 180deg !important;
                }
                img {
                    height: 10px;
                }
            }
        }
    }
    .inner {
        white-space: nowrap;
        transition: transform 0.3s;
    }
    .carousel-indicator {
        display: flex;
        width: 50px;
        flex: 0;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 16px;

        .indicator {
            width: 6px;
            height: 6px;
            border-radius: 3px;
            padding: 0px 2px;
            background-color: #f29e9a;

            &.active {
                width: 14px;
                background-color: #990000;
            }
        }
    }
}

// COMPATIBILIDADE COM NOVO DS - TODO: Deve ser 100% suprido pela toro-styles no futuro
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    font-family: Roboto !important;
}

.btn-primary--solid, .btn-primary {
    background-color: #900 !important;
    border-color: unset !important;
    box-shadow: unset !important;
    &:hover {
        background-color: #310a05 !important;
    }
}

.link-primary, .link-store {
    color: #900 !important;
}

.form-input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid #EAE9EF;
    outline: 0;
    height: 2.5rem;
    width: 100%;
    padding: .5rem 1rem;
    margin-bottom: .5rem;
    line-height: 24px;
    transition: all .3s;
    color: #141414;
    background-color: unset !important;
}

.input-field.input-error .form-input {
    color: unset;
    border-color: #EAE9EF;
    background-color: unset !important;
}

.btn-outline-primary {
    border-color: #900 !important;
    color: #900 !important;
    background-color: #fffFFF80 !important;

    &:hover {
        background-color: #ffa29C33 !important;
    }
}